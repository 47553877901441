/* eslint-disable @typescript-eslint/no-explicit-any */

import { apiProfesores } from "./apiBaseQuery";

export const coursesServices: any = apiProfesores.injectEndpoints({
  endpoints: (builder) => ({
    getCoursesByPeriod: builder.mutation({
      query: (params) => {
        return {
          url: `/CourseList/Get`,
          method: "GET",
          params,
        };
      },
    }),
    downloadCourses: builder.mutation({
      query: (body) => {
        return {
          url: `/CourseList/DescargarExcel`,
          method: "POST",
          body,
          responseHandler: "text",
        };
      },
    }),
    downloadStudents: builder.mutation({
      query: (body) => {
        return {
          url: `/CourseDetail/DownloadStudentsExcel`,
          method: "POST",
          body,
          responseHandler: "text",
        };
      },
    }),
    getDashboardCourses: builder.mutation({
      query: () => {
        return {
          url: `/Dashboard/Get`,
          method: "GET",
        };
      },
    }),
    getTeacherPeriods: builder.mutation({
      query: () => {
        return {
          url: `/Periods/Get`,
          method: "GET",
        };
      },
    }),
    getCourseDetailGeneral: builder.mutation({
      query: (seccionId) => {
        return {
          url: `/CourseDetail/GetGeneralInfo/${seccionId}`,
          method: "GET",
        };
      },
    }),
    getCourseStudents: builder.mutation({
      query: (data) => {
        return {
          url: `/CourseDetail/GetStudents/${data.seccionId}?name=${data.nombre}&status=${data.estatus}`,
          method: "GET",
        };
      },
    }),
    getCourseSchedule: builder.mutation({
      query: (seccionId) => {
        return {
          url: `/CourseDetail/GetSchedule/${seccionId}`,
          method: "GET",
        };
      },
    }),
    getCourseActivities: builder.mutation({
      query: (data) => {
        return {
          url: `/CourseDetail/GetActivities/${data.seccionId}?title=${data.titulo}&type=${data.tipoId}`,
          method: "GET",
        };
      },
    }),
    getActivityTypes: builder.mutation({
      query: () => {
        return {
          url: `/ActivityTypes/Get`,
          method: "GET",
        };
      },
    }),
    getActivityDefaultValues: builder.mutation({
      query: (seccionId) => {
        return {
          url: `/Activities/Create/${seccionId}`,
          method: "GET",
        };
      },
    }),
    getActivitiesFromMoodle: builder.mutation({
      query: (data) => {
        return {
          url: `/Activities/GetMoodleActivities?CursoId=${data.curso}&Seccion=${data.seccion}&Name=${data.name}`,
          method: "GET",
        };
      },
    }),
    createMoodleUrl: builder.mutation({
      query: (data) => {
        return {
          url: `/Activities/GetMoodleCreateUrl?CursoId=${data.curso}&Seccion=${data.seccion}&Name=${data.name}`,
          method: "GET",
          responseHandler: "text",
        };
      },
    }),
    getActivityById: builder.mutation({
      query: (id) => {
        return {
          url: `/Activities/Update/${id}`,
          method: "GET",
        };
      },
    }),
    createCourseActivity: builder.mutation({
      query: (data) => {
        return {
          url: `/Activities/Create`,
          method: "POST",
          body: data,
        };
      },
    }),
    updateCourseActivity: builder.mutation({
      query: (body) => {
        const id = body.id;
        delete body.id;
        return {
          url: `/Activities/Update/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    updateActivityPoints: builder.mutation({
      query: (body) => {
        return {
          url: `/CourseDetail/UpdatePoints`,
          method: "PUT",
          body,
        };
      },
    }),
    deleteActivity: builder.mutation({
      query: (actividadId) => {
        return {
          url: `/Activities/Delete/${actividadId}`,
          method: "DELETE",
        };
      },
    }),
    getUserTeacherInfo: builder.mutation({
      query: () => {
        return {
          url: "/Dashboard/GetInfoGeneral",
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetCourseDetailGeneralMutation,
  useGetCourseStudentsMutation,
  useGetCourseScheduleMutation,
  useGetCourseActivitiesMutation,
  useUpdateCourseActivityMutation,
  useGetCoursesByPeriodMutation,
  useDownloadCoursesMutation,
  useDownloadStudentsMutation,
  useGetTeacherPeriodsMutation,
  useGetDashboardCoursesMutation,
  useCreateCourseActivityMutation,
  useGetActivityTypesMutation,
  useGetActivityDefaultValuesMutation,
  useGetActivityByIdMutation,
  useUpdateActivityPointsMutation,
  useDeleteActivityMutation,
  useGetActivitiesFromMoodleMutation,
  useCreateMoodleUrlMutation,
  useGetUserTeacherInfoMutation,
} = coursesServices;
