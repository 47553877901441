import React from "react";
import { ArrayUtils } from "@nubeteck/utils";
import { Checkbox, DatePicker, Input, Radio, Select } from "antd";
import EditorInput from "src/components/other/html-editor/html-editor";
import CodeEditor from "@uiw/react-textarea-code-editor";
import {
  BOOLEAN,
  CLAVE,
  CODE_EDITOR,
  EDITOR,
  EMAIL,
  FECHA,
  LONG_TEXT,
  MULTI_OPTIONS,
  NUMBER,
  OPTIONS,
  RADIO,
  SHORT_TEXT,
} from "src/constants";
import { ISettingsField } from "src/Interfaces/generalSettings";

const ConfigurationInput = ({
  data,
  onChange,
  onBlur,
}: {
  data: ISettingsField;
  onChange: (value: any) => void;
  onBlur?: () => void;
}) => {
  const inputProps = React.useMemo(
    () => ({
      name: `${data.nombre}`,
      options:
        (data.tipo === "Opciones" || data.tipo === "MultiOpciones" || data.tipo === "Radio") &&
        data.opciones &&
        data.opciones.length > 0
          ? ArrayUtils.selectLabelValue(
              data?.opciones,
              Object.keys((data?.opciones ?? [])[0])[0],
              Object.keys((data?.opciones ?? [])[0])[1],
            )
          : [],
      defaultValue: data.valor,
    }),
    [data.nombre, data.opciones, data.valor, data.tipo],
  );

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const mapping = React.useMemo<Record<string, React.JSX.Element>>(
    () => ({
      [FECHA]: <DatePicker {...inputProps} onChange={(e) => onChange(e)} />,
      [RADIO]: (
        <Radio.Group {...inputProps} size="large" onChange={(e) => onChange(e.target.value)} />
      ),
      [CLAVE]: <Input {...inputProps} type="password" onChange={(e) => onChange(e.target.value)} />,
      [EDITOR]: (
        <EditorInput
          useVariables={data.useVariables}
          variables={data.variables}
          {...inputProps}
          onChange={(val) => onChange(val)}
          onBlur={onBlur}
        />
      ),
      [CODE_EDITOR]: (
        <CodeEditor
          value={inputProps.defaultValue}
          language={data.language}
          placeholder="Introduzca el código"
          onChange={(evn: any) => onChange(evn.target.value)}
          padding={15}
          style={{
            backgroundColor: "#f5f5f5",
            fontFamily:
              "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
          }}
        />
      ),
      [MULTI_OPTIONS]: (
        <Select
          {...inputProps}
          showSearch
          filterOption={filterOption}
          optionFilterProp="children"
          mode="multiple"
          placeholder="Selecciona una opción"
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      [EMAIL]: <Input {...inputProps} type="email" onChange={(e) => onChange(e.target.value)} />,
      [NUMBER]: <Input {...inputProps} type="number" onChange={(e) => onChange(e.target.value)} />,
      [SHORT_TEXT]: (
        <Input
          {...inputProps}
          type="text"
          placeholder="Escribe un texto"
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      [LONG_TEXT]: (
        <Input.TextArea
          {...inputProps}
          rows={2}
          placeholder="Escribe una descripción"
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      [OPTIONS]: (
        <Select
          style={{ minWidth: 100 }}
          allowClear
          showSearch
          filterOption={filterOption}
          optionFilterProp="children"
          {...inputProps}
          placeholder="Selecciona una opción"
          onChange={(e) => {
            onChange(e);
          }}
        />
      ),
      [BOOLEAN]: (
        <Checkbox
          {...inputProps}
          defaultChecked={inputProps.defaultValue == "true" || inputProps.defaultValue == true}
          onChange={(e) => onChange(e.target.checked)}
        >
          {data?.label}
        </Checkbox>
      ),
    }),
    [inputProps, onChange, data, onBlur],
  );

  return mapping[data.tipo] ?? `[NO INPUT]: ${data.descripcion}`;
};

export default ConfigurationInput;
