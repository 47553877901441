import {
  HeadingSubtitleStyled,
  LargeHeadingStyled,
  SectionHeaderStyled,
} from "src/components/custom/texts/texts.styled";
import { Button, Flex, Spin, Timeline } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TimelineGradesCustom from "src/components/custom/timeline/timeline-grades";
import { useGetGradesLogsMutation } from "src/services/gradesServices";

function GradesHitorial() {
  const navigate = useNavigate();
  const { seccionId } = useParams();
  const [getGradesLogs, { data, isLoading }] = useGetGradesLogsMutation();

  useEffect(() => {
    getGradesLogs(seccionId);
  }, [getGradesLogs, seccionId]);

  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      {isLoading ? (
        <Spin spinning />
      ) : (
        <>
          <SectionHeaderStyled>
            <div>
              <LargeHeadingStyled>
                Cambios en calificaciones del curso {data?.info?.nombre}
              </LargeHeadingStyled>
              <HeadingSubtitleStyled>
                Sección: {data?.info?.seccion} - Horario:{" "}
                {data?.info?.horario?.map((hor: string) => hor)}
              </HeadingSubtitleStyled>
            </div>
            <Button onClick={() => navigate(-1)}>Volver atrás</Button>
          </SectionHeaderStyled>

          <div style={{ paddingTop: "10px" }}>
            <Timeline
              items={data?.logs?.map((item: any, index: number) => ({
                children: (
                  <div key={index}>
                    <TimelineGradesCustom data={[item]} />
                  </div>
                ),
              }))}
            />
          </div>
        </>
      )}
    </Flex>
  );
}
export default GradesHitorial;
