import { NoResultsSVG } from "src/assets";
import {
  HeadingSubtitleStyled,
  LargeHeadingStyled,
  SectionFiltersHeaderStyled,
  SectionHeaderStyled,
  SmallHeadingStyled,
} from "src/components/custom/texts/texts.styled";
import { FlexStyled } from "src/components/registration/register-introduction/register-introduction.styled";
import { IAttendance } from "@/Interfaces/teacherCourses";
import {
  useGetAttendanceDatesMutation,
  useGetAttendanceListMutation,
  useGetAttendanceStatusMutation,
  useSaveAttendanceMutation,
} from "src/services/attendanceServices";
import { Box } from "@nubeteck/components";
import { Button, Card, Empty, Form, Image, Input, Select } from "antd";
import { Content } from "antd/es/layout/layout";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import { ColumnContainer } from "src/components/layout/main-layout/main-layout.styled";
import {
  EditableCell,
  EditableRow,
} from "src/components/custom/editable-table-items/editable-table-items";
import { useNavigate, useParams } from "react-router-dom";
import { ArrayUtils } from "@nubeteck/utils";

function CourseAttendance() {
  const { seccionId } = useParams();
  const navigate = useNavigate();
  const [getAttendance, { data, isLoading }] = useGetAttendanceListMutation();
  const [getAttendanceStaus, { data: statusList }] = useGetAttendanceStatusMutation();
  const [saveAttendance, { isLoading: isLoadingSaving, isSuccess, error }] =
    useSaveAttendanceMutation();
  const [getDates, { data: dates }] = useGetAttendanceDatesMutation();
  const [fechaFiltro, setFechaFiltro] = useState(null);
  const [estudianteNombre, setEstudianteNombre] = useState<string>("");
  const [filasSeleccionadas, setFilasSeleccionadas] = useState<IAttendance[]>([]);
  const [keysSeleccionadas, setKeysSeleccionadas] = useState<React.Key[]>([]);
  const [asistencia, setAsistencia] = useState<IAttendance[]>([]);

  const estatusOpciones = ArrayUtils.selectLabelValue(
    statusList,
    "asistenciaEstadoNombre",
    "asistenciaEstadoCodigo",
  );

  useEffect(() => {
    getDates(seccionId);
    getAttendanceStaus();
  }, [getDates, seccionId, getAttendanceStaus, isSuccess]);

  useEffect(() => {
    getAttendance({ seccionId, nombre: estudianteNombre, fecha: fechaFiltro });
    setFilasSeleccionadas([]);
    setKeysSeleccionadas([]);
  }, [getAttendance, seccionId, estudianteNombre, fechaFiltro, isSuccess]);

  useEffect(() => {
    setAsistencia(data?.asistencias);
  }, [data]);

  useEffect(() => {
    if (error) toast.error(error?.data?.detail, toastErrorStyle);
    if (isSuccess) {
      toast.success("Asistencia actualizada correctamente", toastSuccessStyle);
    }
  }, [error, isSuccess]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IAttendance[]) => {
      setFilasSeleccionadas(selectedRows);
      setKeysSeleccionadas(selectedRowKeys);
    },
    getCheckboxProps: (record: IAttendance) => ({
      disabled: record.estudianteNombre === "Disabled", // Column configuration not to be checked
      name: record.estudianteNombre,
    }),
    selectedRowKeys: keysSeleccionadas,
  };

  const defaultColumns = React.useMemo<ColumnsType<IAttendance>>(
    () => [
      {
        title: "Estudiante",
        dataIndex: "estudianteNombre",
      },
      {
        title: "Estatus de asistencia",
        dataIndex: "estado",
        editable: true,
        fieldType: "options",
        options: estatusOpciones,
        defaultValue: "3Presente",
      },
      {
        title: "Comentarios",
        dataIndex: "comentarios",
        editable: true,
        fieldType: "richText",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [estatusOpciones],
  );

  const actualizarFilasSeleccionadas = (value: string) => {
    const newValues = asistencia?.map((a) => ({
      ...a,
      estado: filasSeleccionadas.find((f) => f.estudianteId === a.estudianteId) ? value : a.estado,
    }));
    setAsistencia(newValues);
  };

  const handleSave = (row: IAttendance) => {
    const tempArr = asistencia.map((asis) =>
      asis.estudianteId !== row?.estudianteId ? asis : row,
    );
    setAsistencia(tempArr);
  };

  const columns = defaultColumns.map((col: any) => {
    return {
      ...col,
      onCell: (record: IAttendance) => ({
        record,
        fieldType: col.fieldType,
        options: col.options,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        defaultValue: col.defaultValue,
      }),
    };
  });

  return (
    <ColumnContainer>
      <SectionHeaderStyled>
        <div>
          <LargeHeadingStyled>
            Registro de asistencia del curso {data?.info?.nombre}
          </LargeHeadingStyled>
          <HeadingSubtitleStyled>
            Sección: {data?.info?.seccion} - Horario:{" "}
            {data?.info?.horario?.map((hor: string) => hor)}
          </HeadingSubtitleStyled>
        </div>
        <Button onClick={() => history.back()} type="default">
          Volver atrás
        </Button>
      </SectionHeaderStyled>
      <SectionFiltersHeaderStyled>
        <FlexStyled style={{ gap: 30, flexWrap: "wrap" }}>
          <Form.Item label="Fecha">
            <Select
              style={{ minWidth: 230 }}
              allowClear
              value={fechaFiltro}
              options={dates}
              onChange={(value) => {
                setFechaFiltro(value);
              }}
              placeholder="Seleccione una fecha"
            />
          </Form.Item>
          <Form.Item label="Búsqueda de estudiante por nombre">
            <Input
              allowClear
              value={estudianteNombre ?? undefined}
              onChange={(e) => {
                setEstudianteNombre(e.target.value);
              }}
              placeholder="Búsqueda"
            />
          </Form.Item>
        </FlexStyled>
        <FlexStyled style={{ gap: 20 }}>
          <Button
            type="default"
            onClick={() =>
              navigate(`/teacher/courses/course-attendance/${seccionId}/historial-attendance`)
            }
          >
            Ver historial de cambios
          </Button>
          <Button
            disabled={fechaFiltro == null}
            type="primary"
            loading={isLoading || isLoadingSaving}
            onClick={() => {
              saveAttendance({ fecha: fechaFiltro, asistencias: asistencia, seccionId });
            }}
          >
            Guardar cambios
          </Button>
        </FlexStyled>
      </SectionFiltersHeaderStyled>
      {filasSeleccionadas?.length > 0 && (
        <SectionFiltersHeaderStyled>
          <Form.Item label="Estatus de asistencia (casillas marcadas)">
            <Select
              options={estatusOpciones}
              allowClear
              placeholder="Estatus"
              onChange={(e) => actualizarFilasSeleccionadas(e)}
              style={{ minWidth: 230 }}
            />
          </Form.Item>
        </SectionFiltersHeaderStyled>
      )}
      <Content style={{ marginTop: "16px" }}>
        <Card bodyStyle={{ padding: 0, width: "100%" }}>
          {fechaFiltro ? (
            <Table<IAttendance>
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              components={components}
              locale={{
                emptyText: (
                  <Box>
                    <Box>
                      <SmallHeadingStyled>No se encontraron estudiantes</SmallHeadingStyled>
                      <Image
                        src={NoResultsSVG}
                        preview={false}
                        alt="Ilustración sin resultados"
                        width={200}
                      />
                    </Box>
                  </Box>
                ),
              }}
              loading={isLoading}
              columns={columns}
              dataSource={asistencia}
              rowKey={(v) => v.estudianteId}
              pagination={{
                defaultPageSize: 20,
                showSizeChanger: true,
                style: { marginRight: "30px" },
              }}
              scroll={{ x: 800 }}
            />
          ) : (
            <Empty
              style={{ height: 300, padding: 50 }}
              description="Seleccione una fecha para mostrar el registro de asistencia"
            />
          )}
        </Card>
      </Content>
    </ColumnContainer>
  );
}

export default CourseAttendance;
